import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Everything You Need To Know About Your Dog’s Oral Health" 
    description="Your dogs’ oral health matters more than you realize. Are you brushing your pup’s teeth everyday? Find out everything you need to know to ensure good oral health." 
    keywords="pet teeth, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Everything you need to know about your dog's oral health.</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p className="lead">Improve Their Oral Health & Their Lives </p>
            <p>When it comes to our four-legged babies, their dental health seems to be something that even the best dog moms and dads often forget to check, or maintain. Dog oral health is an essential part of the overall health of your pup, and whether you are dealing with a new puppy or an aging doggy who has been around the block a few times, its never to late to start taking care of their oral heath! With that in mind, we have brought together some of the essential things that you need to know about your dog's oral health.</p>
            <img src={ require("../../images/desktop/dog_desc_only.png" )} alt="Dog health" className="bl__img" />
            <h3>Hard vs Soft Food</h3>
            <p>For our four-legged friends, it often starts with what they eat. In terms of dog food, when it comes to oral health hard food has been proven to be healthier for dogs of all sizes. The reason for this is soft food often will get stuck in the various nooks and crannies within the mouth, and will slowly cause decay at the root of the tooth. Hard food, on the other hand, will help clear out the plaque and will limit the ability for stuck food to cause issues in the future.</p>
            <img src={ require("../../images/desktop/Dogs-Brushing-Teeth-1.png" )} alt="Dogs Brushing Teeth 1" className="bl__img" />
            <h3>Brush their teeth!</h3>
            <p>That is right, you are not the only one who needs to get used to regular brushings. A dog should have their teeth brushed a couple of times a week to help eliminate any issues that stuck food or plaque can cause. Make sure to find a dog-specific toothpaste as they are not built to handle human toothpaste, especially any with fluoride!</p>
            <img src={ require("../../images/desktop/Dogs-Brushing-Teeth-2.png" )} alt="Dogs Brushing Teeth 2" className="bl__img" />
            <h3>Chew toys and bones</h3>
            <p>In today’s ever-expanding pet market, there are tonnes of choice’s on the market for synthetic bones and chew toys to help strengthen your dog's gums and teeth. Make sure to choose a toy that is not too tough, as hard objects can, in fact, cause broken teeth! A great bone or toy will allow your dog to get rid of plaque buildup and help keep the gums strong.</p>
            <img src={ require("../../images/desktop/chew-toy.png" )} alt="Chew Toy" className="bl__img" />
            <h3>Water additives</h3>
            <p>Adding a dental rinse to your pups drinking bowls can help reduce the chances of infection, help improve their breath and help reduce plaque build up leading to decay. Additionally, it will help reduce the chances of infection for your little one.</p>
            <img src={ require("../../images/desktop/water_additive.png" )} alt="Water Additive" className="bl__img" />
            <h3>Start early</h3>
            <p>Oral health is an important part of your dog's care, and if you start a dog early they will learn to understand it is for their own good. As a puppy, start to brush their teeth and get them used to inspect their mouth. This will eliminate any bad behaviours in the future, and limit their want to snap or bite your hand when you are helping them with their doggy dental care.</p>
            <img src={ require("../../images/desktop/Dogs-Brushing-Teeth-3.png" )} alt="Dogs Brushing Teeth 3" className="bl__img" />
            <h3>Head to the vet every 6-12 months</h3>
            <p>Much like our human dentists, it is best to visit the vet at least once a year to get an overall checkup that includes their mouth. Typically, a normal examination will include a tooth check-up, but it never hurts to ask the vet to take a look just in case. Plus, with regular visits, you can avoid the headaches that often come from large vet bills due to an issue that could have been caught early via a routine checkup.</p>
            <p>Dental health is an important part of any dog health plan, and a healthy mouth means a happy pup! Did we miss any tips, let us know your favourite doggy dental tips by tweeting to us @TheSmileStylistTO</p>
          </div>
          <div className="col-sm-4 blog-right">
            <a href="/virtual-smile-makeover"><img alt="Virtual Smile Consultation" src={ require("../../images/desktop/get-virtual-smile.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.3s" /></a>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage